import React, {Fragment, useState} from "react";
import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY, fetchUser} from "./auth-api";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {LogOutButton} from "./LogOutButton";
import {Button, Container, Navbar} from "react-bootstrap";
import {fetch2025ContestStatus, fetchQuestions, join2025Contest, updatePrediction} from "./api";
import {QuestionResponse} from "./dataTypes";
import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';

export const Questions2025 = () => {
    const [authToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const {data: user, isLoading: userLoading} = useQuery(["identity"], (_context) => fetchUser(),
        {
            enabled: !!authToken,
            retry: false
        })
    const {data: questions, isLoading: questionsLoading} = useQuery(['questions-2025'], fetchQuestions);

    const navigate = useNavigate();

    if (!authToken) {
        navigate("/login");
    }

    if (questionsLoading || userLoading) {
        return <Fragment><p>Loading...</p>
            <LogOutButton/></Fragment>;
    }

    if (!questions || !user) {
        return <Fragment><p>Can't fetch data. Make sure you're logged in. <a href={"/login"}>Log in</a></p>
            <LogOutButton/></Fragment>;
    }

    const hasJoined = !!questions[0].userResponse;
    return <Fragment>
        <Container className="PageContentWidth">
            <Navbar className="justify-content-between">
                <Navbar.Brand><a href={"/"} className={"text-decoration-none text-reset"}>Predictions</a></Navbar.Brand>
                <div>Logged in as {user.displayName} <LogOutButton/></div>

            </Navbar>
            <h1 className="text-center mt-3 mb-5">{'2025 Contest'}</h1>
            {hasJoined ? <AnswerPanel/> : <JoinContestPanel/>}
        </Container>
    </Fragment>
}

const JoinContestPanel = () => {
    const {data: questions, refetch} = useQuery(['questions-2025'], fetchQuestions);
    const {data: status} = useQuery(['questions-2025-status'], fetch2025ContestStatus);

    const canJoin = !status?.hasEnded;

    return <Fragment>
        <p>Would you like to join the 2025 prediction contest?</p>
        <Button onClick={() => join2025Contest().then(() => refetch())} disabled={!canJoin}>Join contest</Button>
        {!canJoin && <p className={"text-danger"}>The contest has ended. You can no longer join.</p>}
        <p className={'mt-5'}>Here's a preview of this year's questions.</p>
        <ul>
            {questions && questions.map((question, index) => (<li key={index}>{question.text}</li>))}
        </ul>
        <p>You can update your predictions up to 15 Jan 2025.</p>
    </Fragment>
}

const AnswerPanel = () => {
    const {data: questions, refetch} = useQuery(['questions-2025'], fetchQuestions);
    const {data: status} = useQuery(['questions-2025-status'], fetch2025ContestStatus);
    const [showSavedToast, setShowSavedToast] = useState(false);

    const hasEnded = status?.hasEnded;

    const beforeSubmit = () => setShowSavedToast(false);
    const afterSubmit = () => refetch()
        .then(() => setShowSavedToast(true))

    return <Fragment>
        <div
            style={{
                position: "fixed",
                top: "20px",
                left: "20px",
                width: "100%",
                zIndex: 1050, // Make sure it appears above other elements
            }}
        >
            <ToastContainer>
                <Toast show={showSavedToast} onClose={() => setShowSavedToast(false)} delay={3000} autohide>
                    <Toast.Body>
                        ✅ Prediction updated
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
        <p>All predictions start at 50%. You can update your predictions until 15 Jan 2025.</p>
        {hasEnded && <p className={"text-danger"}>The contest has ended. You can no longer update your predictions.</p>}
        <h2 className={"mb-4"}>Questions</h2>
        {questions && questions.map((question, index) => (
            <AnswerCard key={index} question={question} afterSubmit={afterSubmit} beforeSubmit={beforeSubmit}
                        canUpdate={!hasEnded}/>))}
    </Fragment>
}

const AnswerCard = ({
                        question, afterSubmit, beforeSubmit, canUpdate
                    }: {
    question: QuestionResponse,
    afterSubmit: () => void,
    beforeSubmit: () => void,
    canUpdate: boolean
}) => {
    const userPrediction = question.userResponse?.prediction

    const [temporaryValue, setTemporaryValue] = useState(userPrediction);
    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemporaryValue(Number(e.target.value));
    };
    const handleSliderRelease = () => {
        beforeSubmit();
        temporaryValue && temporaryValue !== userPrediction && updatePrediction(question.questionId, temporaryValue)
            .then(() => afterSubmit());
    };
    return <Fragment>
        <h4 className={"mt-3"}>{question.number}. {question.text}</h4>
        {question.context && <p>{question.context}</p>}
        Your prediction: {temporaryValue}%
        <Form.Range
            min={1}
            max={99}
            step={1}
            value={temporaryValue}
            onChange={handleSliderChange}
            onMouseUp={handleSliderRelease}
            onTouchEnd={handleSliderRelease}
            disabled={!canUpdate}
        />
    </Fragment>
}