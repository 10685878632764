import {FormEvent, Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "usehooks-ts";
import {AUTH_TOKEN_KEY, fetchUser} from "./auth-api";

export interface UserResponse {
    userId: string,
    email: string,
    displayName: string
}


export const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [, setAuthToken] = useLocalStorage<string | null>(AUTH_TOKEN_KEY, null);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const authToken = 'Basic ' + btoa(`${email}:${password}`)
        setAuthToken(authToken);
        setError(null);

        fetchUser()
            .catch(() => {
                setError("⚠️ Error logging in. Double-check your email and password.");
                setAuthToken(null);
            })
            .then((user) => {
                if (user?.userId) {
                    navigate("/")
                }
            })
    };

    return (<Fragment>
            <h3>Login</h3>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Email:
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className={'mt-3 mb-3'}>
                    <label>
                        Password:
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
                {error && <p className={'mt-3'}>{error}</p>}
            </form>
            <p className={'mt-3'}>Forgot your password? <a href={'/register-password-reset'}>Reset your password</a></p>
        </Fragment>
    );
}