import axios from 'axios';
import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { AUTH_TOKEN_KEY } from './auth-api';
import { getApiHost } from './config';

export function TravelBot() {
  const { data: accountStatus, isLoading: checkingAccount, error: accountError, refetch } = useQuery(
    'telegramAccount',
    async () => {
      const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
      if (!tokenMaybe) {
        return Promise.resolve(null); 
      }
      const parsedToken = `${JSON.parse(tokenMaybe)}`;
      const { data } = await axios.get(getApiHost() + '/travel-alerts/telegram-account', {
        headers: {
          "Accept": "application/json",
          "Authorization": parsedToken
        }
      });
      return data;
    }
  );

  const generateLinkMutation = useMutation(
    async () => {
      const tokenMaybe = localStorage.getItem(AUTH_TOKEN_KEY);
      if (!tokenMaybe) {
        return Promise.resolve(null);
      }
      const parsedToken = `${JSON.parse(tokenMaybe)}`;
      const { data } = await axios.post(getApiHost() + '/travel-alerts/telegram-link', {}, {
        headers: {
          Authorization: parsedToken
        }
      });
      return data;
    }
  );

  // Start polling when link is clicked
  const handleLinkClick = () => {
    const pollInterval = setInterval(() => {
      refetch();
      // If account is linked, stop polling
      if (accountStatus?.hasTelegramAccount) {
        clearInterval(pollInterval);
      }
    }, 2000); // Poll every 2 seconds

    // Cleanup interval after 2 minutes
    setTimeout(() => {
      clearInterval(pollInterval);
    }, 120000);
  };

  if (checkingAccount) {
    return <div>Loading...</div>;
  }

  if (accountError || generateLinkMutation.error) {
    return <div className="error">Failed to load. Please try again.</div>;
  }

  return (
    <div className="travel-bot">
      <h1>Travel Bot</h1>
      
      {accountStatus?.hasTelegramAccount ? (
        <div>
          <p>Your account is linked to Telegram!</p>
          <p>You can now use the Travel Bot on Telegram.</p>
        </div>
      ) : (
        <div>
          <p>Link your account to use the Travel Bot on Telegram</p>
          {generateLinkMutation.data?.url ? (
            <div>
              <p>Click this link to connect your Telegram account:</p>
              <a 
                href={generateLinkMutation.data.url} 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={handleLinkClick}
              >
                Connect Telegram
              </a>
            </div>
          ) : (
            <button 
              onClick={() => generateLinkMutation.mutate()} 
              disabled={generateLinkMutation.isLoading}
            >
              {generateLinkMutation.isLoading ? 'Generating...' : 'Generate Telegram Link'}
            </button>
          )}
        </div>
      )}
    </div>
  );
}