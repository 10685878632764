import React from "react";
import {UserSettingsPage} from "./UserSettingsPage";
import { LoginPage } from "./LoginPage";
import './App.scss'
import { QueryClient, QueryClientProvider } from "react-query";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {RegisterPage} from "./RegisterPage";
import {CreateAccountPage} from "./CreateAccountPage";
import {UserPageV2} from "./UserPageV2";
import {ResetPasswordPage} from "./ResetPasswordPage";
import {RegisterPasswordResetPage} from "./RegisterPasswordResetPage";
import {RegisterFirstPasswordPage} from "./RegisterFirstPasswordPage";
import {RedirectFirstTime} from "./RedirectFirstTime";
import {Questions2025} from "./Questions2025";
import {TravelBot} from "./TravelBot";
import {HomePage} from "./HomePage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/predictions" element={<UserPageV2 />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/user" element={<UserSettingsPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/create-account" element={<CreateAccountPage />} />
            <Route path="/first-password" element={<RegisterFirstPasswordPage />} />
            <Route path="/register-password-reset" element={<RegisterPasswordResetPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/first-time-redirect" element={<RedirectFirstTime />} />
            <Route path="/questions-2025" element={<Questions2025 />} />
            <Route path="/travel-bot" element={<TravelBot />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
