import {FormEvent, Fragment, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {createAccount} from "./auth-api";
import {AxiosError} from "axios";

export const CreateAccountPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const emailBase64 = queryParams.get("email");
    const token = queryParams.get("token");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    if (!emailBase64 || !token) {
        return <p>Broken link. Please try again. <a href={"/register"}>Register</a></p>
    }

    const email = atob(emailBase64);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (password === displayName) {
            setErrorMessage("Password and display name cannot be the same.");
            return;
        }

        createAccount(
            email, password, displayName, token
        )
            .then(() => {
                    navigate("/login")
                },
                (error: AxiosError) => {
                    setErrorMessage("Error creating account.");
                })
    };

    return <Fragment>
        <h2>Create your account</h2>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email: </label>
            <input
                type="email"
                id="email"
                name="email"
                value={email}
                readOnly
                autoComplete="username"
                style={{border: 'none'}}
            />
            <br/>
            <label className={'mt-2'}>
                Password:
            </label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <br/>
            <label className={'mt-2'}>
                Your name:
            </label>
            <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
            />
            <br/>
            <button className={"mt-2"} type="submit">Create account</button>
            {errorMessage && (
                <p className={"text-danger"}>{errorMessage} Please check your details and try again. Remember this link only works for a short amount of
                    time. Request a new link if necessary: <a href={'/register'}>Request</a></p>
            )}
        </form>

    </Fragment>
}